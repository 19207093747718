
import {defineComponent, reactive, onUpdated, ref, unref, toRaw, inject, onMounted} from 'vue';
import Api, {IGetGameListReqParams} from "@/core/Api";
import MathGame from "@/components/games/MathGame.vue";
import WordGame from "@/components/games/WordGame.vue";
import CountGame from "@/components/games/CountGame.vue";
import {
  GameDifficultyEnum,
  GameDifficultyEnumInverse,
  GameDifficultyEnumMap,
  GameDifficultyNameEnum
} from "@/core/enums/gameDifficultyEnum";
import {
  GameCategoryEnum,
  GameCategoryEnumInverse,
  GameCategoryEnumMap,
  GameCategoryNameEnum
} from "@/core/enums/gameCategoryEnum";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: 'Play',
  components: {
    MathGame, WordGame, CountGame
  },
  props: ['selectionData'],
  setup: function (props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component

    const dataStore: any = inject('dataStore');
    const state = reactive({
      openGameId: -1,
    })
    const gamesList: any = ref([]);
    const headerIconsList: any = ref([]);
    const listShowStatuses: any = ref([true]); // first element is open, true in pos 0
    const currentTabComponentName: any = ref('');
    const gameListProps: any = ref({});
    const cardColorArray = ['#FFA882', '#83DAD6', '#CBAACB', '#66AB8C', '#6EC4DB', '#FA7C92'];
    const selection = props.selectionData ? JSON.parse(props.selectionData) : false;

    onMounted(() => {
      if (props.selectionData) { // set selectors (category, difficulty) values if provided by router
        if (selection.difficulty) {
          const selector: HTMLSelectElement = document.getElementById('difficulty-selector') as HTMLSelectElement;
          const data = Object.assign({}, GameDifficultyEnumInverse);
          const nr = Object.keys(data).indexOf(selection.difficulty).toString();
          if (selector) selector.value = nr;
        }
        if (selection.category) {
          const selector: HTMLSelectElement = document.getElementById('category-selector') as HTMLSelectElement;
          const data = Object.assign({}, GameCategoryEnumInverse);
          const nr = Object.keys(data).indexOf(selection.category).toString();
          if (selector) selector.value = nr;
        }
      }
    });

    if (props.selectionData) { // set selectors (category, difficulty) values if provided by router
      if (selection.difficulty) gameListProps.value.difficulty = selection.difficulty;
      if (selection.category) gameListProps.value.category = selection.category;
    }

    const getCardColor = (index: number) => {
      const pos = index % cardColorArray.length;
      return cardColorArray[pos];
    }

    const getGamesList = async (data?: IGetGameListReqParams) => {
      listShowStatuses.value = [true];
      headerIconsList.value = [];
      currentTabComponentName.value = '';
      listenersSet = false
      gamesList.value = [];
      gamesList.value = await Api.getGamesList(data);
      updateCurrentOpenGameComponent(0); // show game on first collapse element
    }
    const reqParams = {...toRaw(gameListProps.value)};
    getGamesList(reqParams);

    let listenersSet = false;
    onUpdated(() => {
      if (!listenersSet) {
        document.querySelectorAll('.accordion-collapse').forEach((collapseItem: any, index: number) => {

          collapseItem.addEventListener('show.bs.collapse', (e: any) => {
            const baseGame: any = gamesList.value[index];
            if (dataStore.state.userPoints < baseGame.pointsRequired) { // not enough points
              const pointsNeeded = baseGame.pointsRequired - dataStore.state.userPoints;
              useToast().error(`Ei ole piisavalt punkte! Vaja veel ${pointsNeeded} Punkti`);
              e.stopPropagation();
              e.preventDefault();
            }
          });
          collapseItem.addEventListener('shown.bs.collapse', () => onCollapseShow(index));
          collapseItem.addEventListener('hidden.bs.collapse', () => onCollapseHide(index));
          listenersSet = true;
        })
      }
    });

    const onCollapseShow = (index: number) => {
      listShowStatuses.value[index] = true
      updateCurrentOpenGameComponent(index);
    }

    const onCollapseHide = (index: number) => {
      listShowStatuses.value[index] = false
    }

    const canShowCollapseInitially = (baseGame: any, index: number) => {
      // open first game if is first in list and user has enough points
      return index === 0 && baseGame.pointsRequired <= dataStore.state.userPoints
    }

    const updateCurrentOpenGameComponent = (index: number) => {
      if (gamesList.value[index]?.pointsRequired > dataStore.state.userPoints) return;
      const gameType = gamesList.value[index]?.type;
      const targetType = gameType ? (gameType.charAt(0).toUpperCase() + gameType.slice(1)) : '';
      currentTabComponentName.value = targetType;
    }

    const onDifficultyChange = (event: any) => {
      const newDifficultyText: string = event.target.selectedOptions[0].text;
      const targetDifficulty = GameDifficultyEnumMap[newDifficultyText];
      if (targetDifficulty === 'NONE') {
        delete gameListProps.value.difficulty;
      } else {
        gameListProps.value.difficulty = GameDifficultyEnum[targetDifficulty];
      }
      const reqParams = {...toRaw(gameListProps.value)};
      getGamesList(reqParams);
    }

    const onCategoryChange = (event: any) => {
      const newCategoryText: string = event.target.selectedOptions[0].text;
      const targetCategory = GameCategoryEnumMap[newCategoryText];
      if (targetCategory === 'NONE') {
        delete gameListProps.value.category;
      } else {
        gameListProps.value.category = GameCategoryEnum[targetCategory];
      }
      const reqParams = {...toRaw(gameListProps.value)};
      getGamesList(reqParams);
    }

    const getHeaderIconClass = (baseGame: any, index: number) => {
      if (baseGame.completionRecord?.length > 0) {
        return headerIconsList.value[index] = 'bi-check-circle'
      } /*else if (baseGame.pointsRequired > dataStore.state.userPoints) {
        return headerIconsList.value[index] = 'bi-lock'
      }*/
    }

    const onGameCompleted = (data: any) => {
      const {elemIndex, response} = data;
      headerIconsList.value[elemIndex] = 'bi-check-circle'

      if (response.alreadyCompleted) {
        useToast().success(`Tubli! Said jälle hakkama.`);
      } else {
        useToast().success(`Tubli! +${response.pointsRewarded} Punkti`);
      }
      if (response.userPointsTotal) {
        dataStore.state.userPoints = response.userPointsTotal;
      }
    }

    const onGameFailed = () => {
      useToast().error('Proovi uuesti');
    }

    return {
      state, getCardColor, gamesList, listShowStatuses, currentTabComponentName,
      GameDifficultyEnum, GameCategoryEnum, GameDifficultyNameEnum, GameCategoryNameEnum,
      onDifficultyChange, onCategoryChange, onGameCompleted, onGameFailed, getHeaderIconClass, headerIconsList,
      canShowCollapseInitially, selection, dataStore,
    };
  },
});

