<template>
  <div class="game math-game view container-fluid my-4">

    <h3 class="m-3">{{ gameData.question }}</h3>

    <div class="pictures justify-content-center my-4">
      <h3 class="equation">{{gameData.equation}}</h3>
    </div>

    <div class="options justify-content-center my-4">
      <!--      <button type="button" class="btn btn-primary">Primary</button>-->
      <button class="answer-option btn btn-lg rounded-pill btn-outline-primary m-2 px-5" type="button"
              v-for="(option, index) in gameData.answerOptions" :key="index" :value="option"
              @click="onOptionClick($event)">
        {{ option }}
      </button>
    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref} from 'vue';
import Api from "@/core/Api";
import IResolveGameRequest from "@/core/interfaces/IResolveGameRequest";
import IResolveGameResponse from "@/core/interfaces/IResolveGameResponse";

export default defineComponent({
  name: 'MathGame',
  props: ['gameId', 'elementIndex'],
  setup(props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component
    const dataStore: any = inject('dataStore');
    const gameData: any = ref({});

    const getGameData = async () => {
      gameData.value = await Api.getGame(props.gameId);
    }
    getGameData();

    const onOptionClick = async (event: any) => {
      const requestData: IResolveGameRequest = {id: props.gameId, answer: event.target.value}
      const response: IResolveGameResponse = await Api.resolveGame(requestData);

      if (response?.status === 'success') {
        emit('gameCompleted', {elemIndex: props.elementIndex, response});
      } else {
        emit('gameFailed');
      }
    }

    return {
      dataStore,
      gameData,
      onOptionClick,
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.game.view {
  background: #FEF7C2;
  border-radius: 5px;
  border: #0000007d solid 2px;
}

</style>

<!--
answerOptions: Array(3)
0: "2"
1: "3"
2: "1"
length: 3
__proto__: Array(0)
equation: "1+1"
id: 1
question: "Mis on liitmise tulemus?"
type: "mathGame"
-->
