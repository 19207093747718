
import {defineComponent, inject, ref} from 'vue';
import Api from "@/core/Api";
import IResolveGameRequest from "@/core/interfaces/IResolveGameRequest";
import IResolveGameResponse from "@/core/interfaces/IResolveGameResponse";

export default defineComponent({
  name: 'WordGame',
  props: ['gameId', 'elementIndex'],
  setup(props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component
    const dataStore: any = inject('dataStore');
    const gameData: any = ref({});

    const getGameData = async () => {
      gameData.value = await Api.getGame(props.gameId);
    }
    getGameData();

    const onOptionClick = async (event: any) => {
      const requestData: IResolveGameRequest = {id: props.gameId, answer: event.target.value}
      const response: IResolveGameResponse = await Api.resolveGame(requestData);

      if (response?.status === 'success') {
        emit('gameCompleted', {elemIndex: props.elementIndex, response});
      } else {
        emit('gameFailed');
      }
    }

    return {
      dataStore,
      gameData,
      onOptionClick,
    };
  }
});
