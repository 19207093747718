<template>
  <div id="play view">

    <div class="container-md px-0 p-0 d-md-flex">
      <div class="
        row gx-0 d-md-flex
        justify-content-md-start flex-md-row
        ">

        <div class="
          col-6 p-0
          d-md-flex flex-md-column px-md-2
          border border-top-0 border-dark border-2
          ">

          <select id="difficulty-selector" class="form-select btn bg-transparent mx-md-3 "
                  aria-label="Difficulty selector" @change="onDifficultyChange($event)">
            <option selected>{{ GameDifficultyNameEnum.NONE }}</option>
            <option value="1">{{ GameDifficultyNameEnum.INTRODUCTION }}</option>
            <option value="2">{{ GameDifficultyNameEnum.PRE_K }}</option>
            <option value="3">{{ GameDifficultyNameEnum.EARLY_K }}</option>
            <option value="4">{{ GameDifficultyNameEnum.MID_K }}</option>
            <option value="5">{{ GameDifficultyNameEnum.LATE_K }}</option>
          </select>
        </div>

        <div class="
          col-6 p-0
          d-md-flex flex-md-column px-md-2
          border border-top-0 border-dark border-2
          ">

          <select id="category-selector" class="form-select btn bg-transparent " aria-label="Category selector"
                  @change="onCategoryChange($event)">
            <option selected>{{ GameCategoryNameEnum.NONE }}</option>
            <option value="1">{{ GameCategoryNameEnum.COUNTING }}</option>
            <option value="2">{{ GameCategoryNameEnum.WORDS }}</option>
            <option value="3">{{ GameCategoryNameEnum.ADDING }}</option>
            <option value="4">{{ GameCategoryNameEnum.SUBTRACTING }}</option>
            <option value="5">{{ GameCategoryNameEnum.MULTIPLICATION }}</option>
          </select>
        </div>

      </div>
    </div>

    <div v-if="gamesList.length < 1 " class="spinner-border m-5" style="width: 4rem; height: 4rem;" role="status"></div>

    <div v-else id="accordionParent" class="accordion accordion-flush container-md px-0 mt-4">

      <div class="accordion-item" v-for="(baseGame, index) in gamesList" :key="baseGame.id">

        <h2 class="accordion-header" :id="`heading-${index}`">
          <button class="accordion-button ps-2" type="button" data-bs-toggle="collapse"
                  :data-bs-target="`#collapse-${index}`" :aria-expanded="canShowCollapseInitially(baseGame, index)"
                  :aria-controls="`collapse-${index}`" :style="{ background: getCardColor(index)}">

            <div class="accordion-header-text container-fluid">

              <div class="row ms-0">
                <div class="col-10">
                  <span style="font-size: 28px">{{ index + 1 }}</span> . {{ baseGame.description }}
                </div>
                <div class="col-2">
                  <i class="bi d-flex float-end" :class="[getHeaderIconClass(baseGame, index), headerIconsList[index]]"
                     style="font-size: 2rem; color: white;"></i>
                  <i class="bi d-flex float-end bi-lock" style="font-size: 2rem; color: white;"
                     v-if="dataStore.state.userPoints < baseGame.pointsRequired"></i>
                  <p class="d-flex float-end m-1" v-if="dataStore.state.userPoints < baseGame.pointsRequired">
                    {{ baseGame.pointsRequired }}P</p>
                </div>
              </div>
            </div>

          </button>
        </h2>

        <div :id="`collapse-${index}`" :class="['accordion-collapse', 'collapse', `${index > 0 ? 'hide' : 'show'}`]"
             :aria-labelledby="`heading-${index}`" data-bs-parent="#accordionParent">
          <div class="accordion-body" :style="{ background: getCardColor(index) }">

            <component v-if="listShowStatuses[index]" :is="currentTabComponentName" v-bind="baseGame"
                       :gameId="baseGame.id" :elementIndex="index" @game-completed="onGameCompleted"
                       @game-failed="onGameFailed">
            </component>

          </div>
        </div>
        <div class="card-under-color container-fluid" :style="{ background: getCardColor(index) }"></div>

      </div>

    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent, reactive, onUpdated, ref, unref, toRaw, inject, onMounted} from 'vue';
import Api, {IGetGameListReqParams} from "@/core/Api";
import MathGame from "@/components/games/MathGame.vue";
import WordGame from "@/components/games/WordGame.vue";
import CountGame from "@/components/games/CountGame.vue";
import {
  GameDifficultyEnum,
  GameDifficultyEnumInverse,
  GameDifficultyEnumMap,
  GameDifficultyNameEnum
} from "@/core/enums/gameDifficultyEnum";
import {
  GameCategoryEnum,
  GameCategoryEnumInverse,
  GameCategoryEnumMap,
  GameCategoryNameEnum
} from "@/core/enums/gameCategoryEnum";
import {useToast} from "vue-toastification";

export default defineComponent({
  name: 'Play',
  components: {
    MathGame, WordGame, CountGame
  },
  props: ['selectionData'],
  setup: function (props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component

    const dataStore: any = inject('dataStore');
    const state = reactive({
      openGameId: -1,
    })
    const gamesList: any = ref([]);
    const headerIconsList: any = ref([]);
    const listShowStatuses: any = ref([true]); // first element is open, true in pos 0
    const currentTabComponentName: any = ref('');
    const gameListProps: any = ref({});
    const cardColorArray = ['#FFA882', '#83DAD6', '#CBAACB', '#66AB8C', '#6EC4DB', '#FA7C92'];
    const selection = props.selectionData ? JSON.parse(props.selectionData) : false;

    onMounted(() => {
      if (props.selectionData) { // set selectors (category, difficulty) values if provided by router
        if (selection.difficulty) {
          const selector: HTMLSelectElement = document.getElementById('difficulty-selector') as HTMLSelectElement;
          const data = Object.assign({}, GameDifficultyEnumInverse);
          const nr = Object.keys(data).indexOf(selection.difficulty).toString();
          if (selector) selector.value = nr;
        }
        if (selection.category) {
          const selector: HTMLSelectElement = document.getElementById('category-selector') as HTMLSelectElement;
          const data = Object.assign({}, GameCategoryEnumInverse);
          const nr = Object.keys(data).indexOf(selection.category).toString();
          if (selector) selector.value = nr;
        }
      }
    });

    if (props.selectionData) { // set selectors (category, difficulty) values if provided by router
      if (selection.difficulty) gameListProps.value.difficulty = selection.difficulty;
      if (selection.category) gameListProps.value.category = selection.category;
    }

    const getCardColor = (index: number) => {
      const pos = index % cardColorArray.length;
      return cardColorArray[pos];
    }

    const getGamesList = async (data?: IGetGameListReqParams) => {
      listShowStatuses.value = [true];
      headerIconsList.value = [];
      currentTabComponentName.value = '';
      listenersSet = false
      gamesList.value = [];
      gamesList.value = await Api.getGamesList(data);
      updateCurrentOpenGameComponent(0); // show game on first collapse element
    }
    const reqParams = {...toRaw(gameListProps.value)};
    getGamesList(reqParams);

    let listenersSet = false;
    onUpdated(() => {
      if (!listenersSet) {
        document.querySelectorAll('.accordion-collapse').forEach((collapseItem: any, index: number) => {

          collapseItem.addEventListener('show.bs.collapse', (e: any) => {
            const baseGame: any = gamesList.value[index];
            if (dataStore.state.userPoints < baseGame.pointsRequired) { // not enough points
              const pointsNeeded = baseGame.pointsRequired - dataStore.state.userPoints;
              useToast().error(`Ei ole piisavalt punkte! Vaja veel ${pointsNeeded} Punkti`);
              e.stopPropagation();
              e.preventDefault();
            }
          });
          collapseItem.addEventListener('shown.bs.collapse', () => onCollapseShow(index));
          collapseItem.addEventListener('hidden.bs.collapse', () => onCollapseHide(index));
          listenersSet = true;
        })
      }
    });

    const onCollapseShow = (index: number) => {
      listShowStatuses.value[index] = true
      updateCurrentOpenGameComponent(index);
    }

    const onCollapseHide = (index: number) => {
      listShowStatuses.value[index] = false
    }

    const canShowCollapseInitially = (baseGame: any, index: number) => {
      // open first game if is first in list and user has enough points
      return index === 0 && baseGame.pointsRequired <= dataStore.state.userPoints
    }

    const updateCurrentOpenGameComponent = (index: number) => {
      if (gamesList.value[index]?.pointsRequired > dataStore.state.userPoints) return;
      const gameType = gamesList.value[index]?.type;
      const targetType = gameType ? (gameType.charAt(0).toUpperCase() + gameType.slice(1)) : '';
      currentTabComponentName.value = targetType;
    }

    const onDifficultyChange = (event: any) => {
      const newDifficultyText: string = event.target.selectedOptions[0].text;
      const targetDifficulty = GameDifficultyEnumMap[newDifficultyText];
      if (targetDifficulty === 'NONE') {
        delete gameListProps.value.difficulty;
      } else {
        gameListProps.value.difficulty = GameDifficultyEnum[targetDifficulty];
      }
      const reqParams = {...toRaw(gameListProps.value)};
      getGamesList(reqParams);
    }

    const onCategoryChange = (event: any) => {
      const newCategoryText: string = event.target.selectedOptions[0].text;
      const targetCategory = GameCategoryEnumMap[newCategoryText];
      if (targetCategory === 'NONE') {
        delete gameListProps.value.category;
      } else {
        gameListProps.value.category = GameCategoryEnum[targetCategory];
      }
      const reqParams = {...toRaw(gameListProps.value)};
      getGamesList(reqParams);
    }

    const getHeaderIconClass = (baseGame: any, index: number) => {
      if (baseGame.completionRecord?.length > 0) {
        return headerIconsList.value[index] = 'bi-check-circle'
      } /*else if (baseGame.pointsRequired > dataStore.state.userPoints) {
        return headerIconsList.value[index] = 'bi-lock'
      }*/
    }

    const onGameCompleted = (data: any) => {
      const {elemIndex, response} = data;
      headerIconsList.value[elemIndex] = 'bi-check-circle'

      if (response.alreadyCompleted) {
        useToast().success(`Tubli! Said jälle hakkama.`);
      } else {
        useToast().success(`Tubli! +${response.pointsRewarded} Punkti`);
      }
      if (response.userPointsTotal) {
        dataStore.state.userPoints = response.userPointsTotal;
      }
    }

    const onGameFailed = () => {
      useToast().error('Proovi uuesti');
    }

    return {
      state, getCardColor, gamesList, listShowStatuses, currentTabComponentName,
      GameDifficultyEnum, GameCategoryEnum, GameDifficultyNameEnum, GameCategoryNameEnum,
      onDifficultyChange, onCategoryChange, onGameCompleted, onGameFailed, getHeaderIconClass, headerIconsList,
      canShowCollapseInitially, selection, dataStore,
    };
  },
});

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

#accordionParent {
  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    color: initial;
    background-color: initial;
  }

  .dropdown button:focus {
    box-shadow: none;
  }

  .accordion-item,
  .accordion-button {
    border-top-left-radius: 50px 50px;
    border-top-right-radius: 50px 50px;
  }

  .card-under-color {
    margin-bottom: -65px;
    height: 65px;
    background: aqua;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-header-text {
    font-family: sigmar one, sans-serif;
    font-size: 18px;
    color: #fff;
    text-shadow: 1px 1px 3px #000;
  }

  .accordion-header {
    .bi-check-circle::before {
      color: #00d200;
    }

    .bi-lock::before {
      color: #393838;
    }
  }
}

.dropdown .btn:focus {
  box-shadow: none;
}

</style>
