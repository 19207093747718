<template>
  <div class="game word-game view container-fluid my-4">

    <h3 class="m-3">{{ gameData.question }}</h3>

    <div class="pictures my-4">
      <div class="game-image m-2" :class="gameData.picture?.name"/>
    </div>

    <div class="word my-4 row justify-content-center">
      <h3 class="partial-word p-3 m-3">{{ gameData.partialWord }}</h3>
    </div>

    <div class="options justify-content-center my-4">

      <button class="answer-option btn btn-lg rounded-pill btn-outline-primary m-2 px-5" type="button"
              v-for="(option, index) in gameData.answerOptions" :key="index" :value="option"
              @click="onOptionClick($event)">
        {{ option }}
      </button>
    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref} from 'vue';
import Api from "@/core/Api";
import IResolveGameRequest from "@/core/interfaces/IResolveGameRequest";
import IResolveGameResponse from "@/core/interfaces/IResolveGameResponse";

export default defineComponent({
  name: 'WordGame',
  props: ['gameId', 'elementIndex'],
  setup(props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component
    const dataStore: any = inject('dataStore');
    const gameData: any = ref({});

    const getGameData = async () => {
      gameData.value = await Api.getGame(props.gameId);
    }
    getGameData();

    const onOptionClick = async (event: any) => {
      const requestData: IResolveGameRequest = {id: props.gameId, answer: event.target.value}
      const response: IResolveGameResponse = await Api.resolveGame(requestData);

      if (response?.status === 'success') {
        emit('gameCompleted', {elemIndex: props.elementIndex, response});
      } else {
        emit('gameFailed');
      }
    }

    return {
      dataStore,
      gameData,
      onOptionClick,
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../../skins/GameAssets";

.game.view {
  background: #FEF7C2;
  border-radius: 5px;
  border: #0000007d solid 2px;
}

.game-image {
  width: 120px;
  height: 120px;
  background-size: contain !important;
  display: inline-block;
}

</style>


<!--
answerOptions: (3) ["O", "Ö", "Ü"]
id: 2
partialWord: "_ÖKULL"
picture: {name: "Owl", key: "IMG_PROFILE_OWL"}
question: "Vali õige täht. Kes või mis on pildil?"
type: "wordGame"
-->
