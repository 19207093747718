<template>
  <div class="game count-game view container-fluid my-4">

    <h3 class="m-3">{{ gameData.question }}</h3>

    <div class="pictures my-4">
      <div class="game-image m-2" :class="gameData.picture.name" v-for="index in gameData.pictureCount"
           :key="index"/>
    </div>

    <div class="options justify-content-center my-4">
      <button class="answer-option btn btn-lg rounded-pill btn-outline-primary m-2 px-5" type="button"
              v-for="(option, index) in gameData.answerOptions" :key="index" :value="option"
              @click="onOptionClick($event)">
        {{ option }}
      </button>
    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref} from 'vue';
import Api from "@/core/Api";
import IResolveGameRequest from "@/core/interfaces/IResolveGameRequest";
import IResolveGameResponse from "@/core/interfaces/IResolveGameResponse";

export default defineComponent({
  name: 'CountGame',
  props: ['gameId', 'elementIndex'],
  setup(props, {attrs, slots, emit}) { // style, and id attributes, that have been passed into this component
    const dataStore: any = inject('dataStore');
    const gameData: any = ref({});

    const getGameData = async () => {
      gameData.value = await Api.getGame(props.gameId);
    }
    getGameData();

    const onOptionClick = async (event: any) => {
      const requestData: IResolveGameRequest = {id: props.gameId, answer: event.target.value}
      const response: IResolveGameResponse = await Api.resolveGame(requestData);

      if (response?.status === 'success') {
        emit('gameCompleted', {elemIndex: props.elementIndex, response});
      } else {
        emit('gameFailed');
      }
    }

    return {
      dataStore,
      gameData,
      onOptionClick,
    };
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./../../skins/GameAssets";

.game.view {
  background: #FEF7C2;
  border-radius: 5px;
  border: #0000007d solid 2px;
}

.game-image {
  width: 120px;
  height: 120px;
  background-size: contain !important;
  display: inline-block;
}

</style>

<!--:style="{ backgroundImage: `url(${require('@/media/bg-1.jpg').default})` }"-->
<!--<div :style="{backgroundImage: 'url('+require('@/assets/'+project.image)+')'}">.</div>-->

<!--answerOptions: ["5", "4", "2"]
0: "5"
1: "4"
2: "2"
id: 5
picture: {name: "Cow", key: "IMG_GAME_COW"}
key: "IMG_GAME_COW"
name: "Cow"
pictureCount: 4
question: "Mitu lehma on kokku?"
type: "countGame"-->
